import React from 'react';
import './Button.css';
import {classNames} from '../utils';

interface Props {
  text: string;
  type?: 'submit' | 'button';
  className?: string;
  onClick?: () => void;
}

export const Button: React.FC<Props> = ({
  text,
  type = 'button',
  className = '',
  onClick,
}) => {
  return (
    <button
      className={classNames('Button', className)}
      type={type}
      onClick={onClick && onClick}
    >
      {text}
    </button>
  );
};
