export const mediaQueries = {
  xsmall: '(max-width: 768px)',
  small: '(max-width: 1024px)',
  medium: '(min-width: 1025px)',
  landscape: '(orientation: landscape)',
};

export type MediaQueries = typeof mediaQueries;

type QueriesKeys = Extract<keyof MediaQueries, string>;

export type MediaQueriesResults = {
  [key in QueriesKeys]: boolean;
};
