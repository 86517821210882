import React, {useEffect, useState} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import useDimensions from 'react-use-dimensions';
import {Button} from './Button/Button';
import {Hamburger} from './Hamburger/Hamburger';
import './Header.css';
import {Color, Logo} from './Logo/Logo';
import {classNames} from './utils';

function shouldDisplayMenu(offset: number): boolean {
  return window.scrollY > window.innerHeight - offset;
}

function useInitialViewport(offset: number): boolean {
  const [isMenuVisible, setMenuVisibility] = useState(false);

  function hideBar() {
    if (shouldDisplayMenu(offset)) {
      setMenuVisibility(true);
    } else {
      setMenuVisibility(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', hideBar);

    return () => {
      window.removeEventListener('scroll', hideBar);
    };
  });

  return isMenuVisible;
}

const Header: React.FC<{siteTitle: string}> = () => {
  const [isOpen, toggleOpen] = useState(false);
  const [ref, {height}] = useDimensions({liveMeasure: false});
  const isMenuVisible = useInitialViewport(height);
  return (
    <header
      ref={ref}
      className={classNames(
        'Header',
        (isMenuVisible || isOpen) && 'Header--visible'
      )}
    >
      <nav className="Header-navigation">
        <div className="Header-navWrapper">
          <AnchorLink href="#top">
            <Logo
              className="Header-logo"
              color={Color.Blue}
              onClick={() => toggleOpen(false)}
            />
          </AnchorLink>
          <input
            type="checkbox"
            id="menu-checkbox"
            className="Header-menuCheckbox"
            aria-label="Toggle main menu"
            checked={isOpen}
            onChange={() => toggleOpen(!isOpen)}
          />
          <label
            htmlFor="menu-checkbox"
            className="Header-menuToggle"
            onClick={e => {
              toggleOpen(!isOpen);
              e.preventDefault();
            }}
          >
            <Hamburger isActive={isOpen} />
          </label>
          <div className="Header-border" />
          <ul className="Header-menu" aria-label="Main menu">
            <li className="Header-item">
              <AnchorLink
                offset={height}
                className="Header-link"
                href="#Services"
                onClick={() => toggleOpen(false)}
              >
                Services
              </AnchorLink>
            </li>
            <li className="Header-item">
              <AnchorLink
                offset={height}
                className="Header-link"
                href="#Approche"
                onClick={() => toggleOpen(false)}
              >
                Approche
              </AnchorLink>
            </li>
            <li className="Header-item">
              <AnchorLink
                offset={height - 1}
                className="Header-link"
                href="#Temoignages"
                onClick={() => toggleOpen(false)}
              >
                Témoignages
              </AnchorLink>
            </li>
            <li className="Header-item">
              <AnchorLink
                offset={height}
                className="Header-link"
                href="#Contact"
                onClick={() => toggleOpen(false)}
              >
                <Button className="Header-button" text="Me contacter" />
              </AnchorLink>
            </li>
            <li className="Header-backdrop" aria-hidden={true} />
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
