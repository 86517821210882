import React, {CSSProperties} from 'react';
import './AbstractTexture.css';
import {classNames} from '../utils';

export enum Opacity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Half = 'half',
}

export interface Props {
  random?: boolean;
  position?: {
    x: number;
    y: number;
  };
  opacity?: Opacity;
  // Is the abstract texture used in split display like the Home or like a full-width background (like the footer)?
  split?: boolean;
}

const MIN_BACKGROUND_POS = 20;
const MAX_BACKGROUND_POS = 80;

function getRandomBackgroundCoordinates(): number {
  return (
    Math.random() * (MAX_BACKGROUND_POS - MIN_BACKGROUND_POS) +
    MIN_BACKGROUND_POS
  );
}

export const AbstractTexture: React.FC<Props> = ({
  random = false,
  position,
  children,
  opacity,
  split = true,
}) => {
  const style: CSSProperties = {};
  if (random) {
    style.backgroundPositionX = `${getRandomBackgroundCoordinates()}%`;
    style.backgroundPositionY = `${getRandomBackgroundCoordinates()}%`;
  } else if (position) {
    style.backgroundPositionX = `${position.x}%`;
    style.backgroundPositionY = `${position.y}%`;
  }

  return (
    <div className="AbstractTexture-container">
      <div
        className={classNames('AbstractTexture', `AbstractTexture--${opacity}`)}
        style={style}
      />
      <div
        className={classNames(
          'AbstractTexture-wrapper',
          split && 'AbstractTexture-wrapper--split'
        )}
      >
        {children}
      </div>
    </div>
  );
};
