export type Empty = null | undefined;

export function classNames(
  ...names: ReadonlyArray<string | Empty | false>
): string {
  return names.reduce((list, currentClass) => {
    return `${list} ${currentClass || ''}`.trim();
  }, '') as string;
}

function generateClassName<T>(base: string, suffix?: T): string {
  if (suffix) {
    if (base.includes('-')) {
      return `${base}-${suffix}`;
    }
    return `${base}--${suffix}`;
  }
  return '';
}

export function theme<T, K>(style: T, size?: K) {
  return (className: string) => {
    const names = [
      className,
      generateClassName(className, style),
      generateClassName(className, size),
    ];
    return names.filter(s => s && s.trim()).join(' ');
  };
}
