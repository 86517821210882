import React from 'react';
import {AbstractTexture, Opacity} from './AbstractTexture/AbstractTexture';
import './Footer.css';
import {Color, Logo} from './Logo/Logo';

export const Footer: React.FC = () => (
  <footer>
    <AbstractTexture
      position={{x: -8, y: 43}}
      opacity={Opacity.Medium}
      split={false}
    >
      <div className="Footer">
        <Logo color={Color.White} />
        <p className="Footer-title">Consulting et développement logiciel</p>
        <ul className="Footer-links">
          <li>
            <a
              className="Footer-link"
              href="https://www.linkedin.com/in/kevynbruyere/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
          <li>
            <a
              className="Footer-link"
              href="https://www.malt.fr/profile/kevynbruyere"
              target="_blank"
              rel="noopener noreferrer"
            >
              Malt
            </a>
          </li>
          <li>
            <a
              className="Footer-link"
              href="https://github.com/kevynb/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </li>
        </ul>
      </div>
    </AbstractTexture>
  </footer>
);
