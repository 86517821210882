import React, {createContext, useContext, useEffect, useState} from 'react';

const defaultValue = {} as Record<string, boolean>;

const BreakpointContext = createContext(defaultValue);

/**
 * Use this to get an object that tells you which media queries are satisfied.
 *
 * Taken from https://medium.com/better-programming/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97
 * mediaQueries object example:
 *
 *  const queries = {
 *    xs: '(max-width: 320px)',
 *    md: '(max-width: 720px)',
 *    lg: '(max-width: 1024px)',
 *  }
 */
export const BreakpointProvider: React.FC<{
  mediaQueries: Record<string, string>;
}> = ({children, mediaQueries}) => {
  const [queryMatch, setQueryMatch] = useState({} as Record<string, boolean>);

  useEffect(() => {
    const mediaQueryLists = initializeMediaQueries(mediaQueries);
    setQueryMatch(getCurrentMediaQueriesMatch(mediaQueryLists));

    function handleMediaQueryChange() {
      setQueryMatch(getCurrentMediaQueriesMatch(mediaQueryLists));
    }

    const {isEventListenerAttached} = setupEventListeners(mediaQueryLists, () =>
      setQueryMatch(getCurrentMediaQueriesMatch(mediaQueryLists))
    );

    return () => {
      if (isEventListenerAttached) {
        Object.keys(mediaQueryLists).forEach(queryName => {
          if (
            mediaQueryLists[queryName].hasOwnProperty('removeEventListener')
          ) {
            mediaQueryLists[queryName].removeEventListener(
              'change',
              handleMediaQueryChange
            );
          } else {
            mediaQueryLists[queryName].removeListener(handleMediaQueryChange);
          }
        });
      }
    };
  }, [mediaQueries]);

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

export function useBreakpoint<T extends Record<string, boolean>>(): T {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context as T;
}

function initializeMediaQueries(
  mediaQueries: Record<string, string>
): Record<string, MediaQueryList> {
  return Object.keys(mediaQueries).reduce((mediaQueryLists, mediaQuery) => {
    if (typeof mediaQueries[mediaQuery] === 'string') {
      // 2 boucles, une pour le setup, 1 pour la verif
      mediaQueryLists[mediaQuery] = window.matchMedia(mediaQueries[mediaQuery]);
    }
    return mediaQueryLists;
  }, {} as Record<string, MediaQueryList>);
}

function getCurrentMediaQueriesMatch(
  mediaQueryLists: Record<string, MediaQueryList>
): Record<string, boolean> {
  return Object.keys(mediaQueryLists).reduce(
    (mediaQueriesMatches, queryName) => {
      mediaQueriesMatches[queryName] = mediaQueryLists[queryName].matches;
      return mediaQueriesMatches;
    },
    {} as Record<string, boolean>
  );
}

function setupEventListeners(
  mediaQueryLists: Record<string, MediaQueryList>,
  handleMediaQueryListener: () => void
) {
  if (!window || !window.matchMedia) {
    return {isEventListenerAttached: false};
  }

  Object.keys(mediaQueryLists).forEach(media => {
    if (mediaQueryLists[media].hasOwnProperty('addEventListener')) {
      mediaQueryLists[media].addEventListener(
        'change',
        handleMediaQueryListener
      );
    } else {
      mediaQueryLists[media].addListener(handleMediaQueryListener);
    }
  });
  return {isEventListenerAttached: true};
}
