import React from 'react';
import SvgLogo from '../../images/logo-inovasi.inline.svg';
import {classNames} from '../utils';
import './Logo.css';

export enum Color {
  Blue = 'blue',
  White = 'white',
}

interface Props {
  color: Color;
  className?: string;
  onClick?: () => void;
}

export const Logo: React.FC<Props> = ({color, className, onClick}) => (
  <SvgLogo
    className={classNames('Logo', `Logo-${color}`, className)}
    alt="Logo Inovasi"
    onClick={onClick}
  />
);
