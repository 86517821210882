import './Hamburger.css';
import React from 'react';
import {classNames} from '../utils';

interface Props {
  isActive: boolean;
}

export const Hamburger: React.FC<Props> = ({isActive}) => {
  return (
    <button
      className={classNames(
        'hamburger',
        'hamburger--squeeze',
        isActive && 'is-active'
      )}
      type="button"
      aria-label="Menu"
      aria-controls="navigation"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};
