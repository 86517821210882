/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {BreakpointProvider} from '../hooks/breakpoint';
import {Footer} from './Footer';

import Header from './Header';
import './layout.css';
import {mediaQueries} from './MediaQueries';

const Layout: React.FC = ({children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <BreakpointProvider mediaQueries={mediaQueries}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main id="top">{children}</main>
        <Footer />
      </div>
    </BreakpointProvider>
  );
};

export default Layout;
